.buildingsContainer {
    grid-template-columns: 1fr, 1fr;
    overflow-y: scroll;
    margin: 15px;
    max-width: 45%;
}

.buyButtonsBox {
    padding: 10px;
}

.accordionBox {
    padding: 5px;
}


