.roll-out {
  animation: roll-out 1.0s;
}

@keyframes roll-out {
  0% { transform: translateY(-50px);
      transform: translateX(-50px);
      opacity: 0;}
  100% { transform: none;
    opacity: 1;
  }
}

.gameButton-btn {
    background:transparent;
    border: 1px solid black;
    margin: 2px;
    width: 120px;
    height: 40px;
}

.gameButton-btn:hover {
    background: rgba(52, 64, 58, 0.3);
  }
  
  .gameButton-btn:active {
    background: rgba(52, 64, 58, 0.8);
  }
  .gameButton-btn .grayOut {
    background:rgba(0,0,0,0.3);
    text-decoration: line-through;
  }