.totalContainer {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.progress-container {
    width: 75%;
    height: 10px;
    background-color: transparent;
    border: 1px solid black;
    position: relative;

  }
  
  .progress-filled,
  .progress-empty {
    height: 100%;
    position: absolute;
    top: 0;
  }
  
  .progress-filled {
    background-color: #05b23c; /* Green */
  }
  
  .progress-empty {
    background-color: transparent;
  }