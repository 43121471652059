body {
    background: rgb(130, 159, 144);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .container {
    /* box-shadow: 1px 1px 12px rgba(52, 64, 58, 0.3); */
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.1fr 0.2ft repeat(8, 1fr);
    row-gap: 2px;
    column-gap: 10px;
    min-height: 700px;
    max-height: 700px;
  }

  .boxShadowClass {
    box-shadow: 1px 1px 12px rgba(52, 64, 58, 0.3);
  }

  .landingPage {
    grid-column: 2 / span 1;
    grid-row: 2 / span 6;
    border: 1px solid black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    text-align: center;
    min-height: 50vh;
  }

  .title-box {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding: 15px 15px;
  }

  .game-Menu-Box {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
    height: min-content;
    border-bottom: 1px solid black;
  }
  
  .main-menu-box {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    height: min-content;
    border-bottom: 1px solid black;
  }
  
  .resources-box {
    grid-column: 1 / span 1;
    grid-row: 2 / span 4;
    border: 1px solid black;
    padding-left: 20px;
    min-height: 50vh;
    
  }
  
  .main-box {
    grid-column: 2 / span 1;
    grid-row: 2 / span 4;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    min-height: 50vh;
    padding-right: 10px;
    padding-left: 5px;
    margin: 0px;
    border: 1px solid black;
    /* min-width: min-content; */
    /* max-width: 450px; */
    overflow-x: scroll;
  }

  .main-box-nav {
    display:flex;
    width: 100%;
    height: 25px;
    align-content: center;
    justify-content: center;
    /* padding-left: 2px;
    padding-top: 2px;
    padding-bottom: 5px;
    margin: 0px;
    font-size: 1.1vw;
    font-weight: bold; */
  }

  .main-box-nav li {
    padding-right: 20px;
    padding-left: 10px;
    justify-content: flex-start;
    align-content: flex-start;
    list-style: none;
    cursor: pointer;
    transition: all 0.4s;
    /* border: 1px solid black; */
    background: rgba(72, 139, 92, 0.232);
    /* box-shadow: 1px -1px 4px rgba(0,0,0,0.1); */
  }

  .main-box-outlet {
    border-top: 1px solid black;
    display:flex;
    justify-content: center;
    align-content: flex-start;
    width: 100%;
    padding-top: 10px;
  }

  .intro-box {
    grid-column: 1 / span 1;
    grid-row: 2 / span 6;
    border: 1px solid black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    text-align: center;
    min-height: 50vh;
    max-width: 500px;
  }

  .buttonBox {
    display:flex;
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-around; 
  }

  .tabContent {
    padding: 0px;
    margin: 0px;
    width: 100%;
  }

  .stats-box {
    grid-column: 3 / span 1;
    grid-row: 3 / span 2;
    border: 1px solid black;
  }
  
  .log-box {
    grid-column: 3 / span 1;
    grid-row: 2 / span 4;
    min-height: 65vh;
    max-height: 65vh;
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: scroll;
    display: flex;
    border: 1px solid black;
    /* flex-direction:column-reverse; */
  }
