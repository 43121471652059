.goalBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
}

.totalGoal-display {
    width: 100%;
    display:flex;
    justify-content: center;
}

.yearsGoal-display {
    width: 100%;
    display:flex;
    justify-content: center;
}