.roll-out {
    animation: roll-out 1.0s;
  }
  
  @keyframes roll-out {
    0% { transform: translateY(-50px);
        transform: translateX(-50px);
        opacity: 0;}
    100% { transform: none;
      opacity: 1;
    }
  }
