
.roll-outLog {
    animation: roll-outLog 1.5s;
  }
  
  @keyframes roll-outLog {
    0% { transform: translateX(-45);
        opacity: 0;}
    100% { transform: none;
      opacity: 1;
    }
  }

  .entryItem {
    animation: entryItem 1.5s;
  }

  @keyframes entryItem {
    0% {transform: translateY(50); 
        opacity: 0;    
    }
    100% { transform: translateY(0);
    opacity: 1;
    }
  }