.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    z-index: 1;
  }

  .settingsWindow {
    z-index: 2;
    position: absolute;
    top: 10%;
    left: 30%;
    border: 1px solid black;
    background-color: rgba(79, 135, 106, 0.8);
    width: 400px;
    height: 500px;
  }

  .settingsWindowText {
    position: relative;
    margin: 10px;
  }

  .settingsWindow #closeBtn {
    position: absolute;
    top: 0%;
    left: 95%;
    background-color: transparent;
    border: none;
  }

  .settingsWindowText button:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
  }

  .settingsButtonContainer {
    display: flex;
    flex-direction: column;
  }

  .singleSetting {
    padding: 6px;
    margin-bottom: 15px;
  }